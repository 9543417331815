body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #3ca3d6;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.apple-music{
width:"100%";
max-width:"660px";
overflow:"hidden";
background:"transparent";
}

.nav-link{
  margin-right:10px;
}
.main-app{
  background-color: black;
}
.header{
  height: 60px;
  border-bottom: 1px solid black;
  background: rgba(9, 36, 50, 0.8);
  color: #ffffff;
  font-size: 3vw;
}
.header a:link { color: white; text-decoration: none;}
.header a:visited { color: white; text-decoration: none; }
.header a:hover { color: white; text-decoration: none; }
.header a:active { color: white;  text-decoration: none;}
.logo {
  margin-left: 5vw;
  width: 25vw;
  float: left;
}
.navbar{
  background: rgba(76, 175, 80, 0.3);
}

.abody{
  width: 100%;
  float: left;
  background-color: silver;
}

.body-right{
  padding: 1vw;
  padding-left: 4vw;
  padding-right: 4vw;
  padding-bottom: 4vw;

}

.list-container{
  overflow-y: hidden;
  float:left;
  clear: both;
  min-height: 100vh;
}

.header-chooser {
  float:right;
  margin-right:10vw;
}
.category-chooser {
  margin-left: 4.1vw;
  font-size: 24px;
}

.item-card{
  position: relative;
  margin-bottom: 2vw;
  width: 300px;
  float: left;
  margin-right: 2vw;
}

.item-card-item-name{
border-top-right-radius: .1vw;
border-top-left-radius: .1vw;
padding: 1vh;
padding-bottom: 1vh;
background-color: #232323;
font-size: 28px;
color: white;
height: 4vh;
}

.item-image{
  width: 100%;
}

.art_box{
  height: 90vh;
  width: 88vw;
  border: 2px solid blue;
  background-color: #444444;
}
.music-box{
  opacity: .96;
  float: left;
  width: 90vw;
  background-color: #444444;
  padding:0;
}
.art-box-image{
  height: 100%;
}

.item-image-wrapper{
  padding-left: 8px;
  padding-right: 8px;
  height: 28vh;
  overflow-y: auto;
  background-color: black;
}
.art-box-item-image-wrapper{
  padding: 8px;
  height: 98%;
  background-color: black;
  float: left;
}
.art-box-item-right-info{
  float:right;
  color: white;
  width: 28%;
  font-size: 8vh;
}

.music-box-item-right-info{
  color: white;
  width: 98%;
  font-size: 4vh;
}

.music-box-item-right-info a:link { color: white; text-decoration: none;}
.music-box-item-right-info a:visited { color: white; text-decoration: none; }
.music-box-item-right-info a:hover { color: white; text-decoration: none; }
.music-box-item-right-info a:active { color: white;  text-decoration: none;}


.album_cover {
  height: 44vh;
}

.music-banner {
  width: 97.6%;
  height: 44vh;
  background-color: #555555;
  margin-bottom: 2vh;
}


.album {
  float: left;
  width: 48%;
  height: 44vh;
  background-color: #555555;
  margin-bottom: 2vh;
  margin-right: 1.5vw;
}

.album-left{
  float: left;
  width: 49%;
}

.album-right{
  float: left;
  width: 45%;
  padding-left: 2vw;
  padding-top: 2vh;
}
.album-ext {
  float: left;
  width: 98%;
  height: 44vh;
  background-color: #555555;
  margin-bottom: 2vh;
  margin-right: 1.5vw;
}
.album-left-ext{
  float: left;
  width: 25%;
}
.album-right-ext{
  float: left;
  width: 20%;
  padding-left: 2vw;
  padding-top: 2vh;
}

.album-links{
  padding-top:2vh;
}

.streaming-link{
  margin-top: 2vh;
}
.subtitle{
  font-size: 2.5vh;
}
.mlogo{
  width: 2vw;
  margin-top:4px;
}
.mlink-img{
  width: 4vw;
  padding: .2vw;
}
.msection {
  width:  100%;
  clear: both;
  background-color: #222222;
  opacity: 95%;
  margin-top: 2vh;
  margin-bottom: 2vh;
}
.clear{
  clear: both;
}
.track-select{
  cursor: pointer;
  font-size: 2VH;
  text-decoration: none;
  background: #6e5e40;
}
@media only screen and (max-width: 950px) {
  .header{
    border-bottom: 1px solid black;
    background: rgba(9, 36, 50, 0.8);
    color: #ffffff;
    font-size: 4.5vw;
  }
  .album_cover {
    width: 100vw;
  }
  .mlink-img{
    width: 25%;
    padding: .2vw;
  }
  .album-left{
    width: 100%;
  }

  .album-right{
    width: 100%;

  }
  .album {
    width: 100%;
    background-color: #555555;
    margin-bottom: 2vh;
    margin-right: 1.5vw;
    height: auto;
  }
  .album-ext{
    width: 100%;
    clear: both;
    height: auto;
  }
  .album-right-ext{
    width: 100%;
    padding-left: 2vw;
    padding-top: 2vh;
  }
  .album-left-ext{
    width: 100%;
  }
  .album-ext {
    float: left;
    width: 100%;
    background-color: #555555;
  }
  .music-box{
    opacity: .96;
    width: 100vw;
    background-color: #444444;
    padding:0;
  }
}
.section-title {
  font-size: 6.5vh;
  font-weight: bold;
}
.section-title-black {
  font-size: 6.5vh;
  font-weight: bold;
  color: black;
}
.band-members{
  font-size: 3.5vh;
}
.soundcloud_embed{
font-size: 10px;
color: #cccccc;
line-break: anywhere;
word-break: normal;
overflow: hidden;
white-space: nowrap;
text-overflow: ellipsis;
font-family: Interstate,Lucida Grande,Lucida Sans Unicode,Lucida Sans,Garuda,Verdana,Tahoma,sans-serif;
font-weight: 100;
}

.soundcloud_embed2{
  color: #cccccc;
  text-decoration: none;
}
.tapes-left{
  float: left;
  width: 29%;
}
.practice-tapes-column{
  width:49%;
  float: left;
}
.practice-tape {
  padding: 1vw;
  float: left;
  width: 90%;
  background-color: #555555;
  margin-bottom: 2vh;
  margin-right: 1.5vw;
}
.zippy{
  width:2vw;
  vertical-align: middle;
}
.song{
  width:4vw;
  vertical-align: middle;
}
.song-small{
  width:3vw;
  vertical-align: middle;
}
.cassette-tape{
  width: 8vw;
  vertical-align: middle;
}
.tapes-song-iframe{
  height: 10vh;
  width: 10vw;
}
.music-file-link-small a:link {font-size: 2VH; text-decoration: none; background: #6e5e40;}
.music-file-link-small a:visited {font-size: 2VH;  text-decoration: none;  background: #6e5e40;}
.music-file-link-small a:hover {font-size: 2VH;  text-decoration: none;  background: #6e5e40;}
.music-file-link-small a:active {font-size: 2VH;  text-decoration: none; background: #6e5e40;}


.music-file-link a:link { text-decoration: none; background: #6e5e40;}
.music-file-link a:visited { text-decoration: none;  background: #6e5e40;}
.music-file-link a:hover { text-decoration: none;  background: #6e5e40;}
.music-file-link a:active { text-decoration: none; background: #6e5e40;}

.music-file-link-black a:link { color: black; text-decoration: none; background: white;}
.music-file-link-black a:visited { color: black; text-decoration: none;  background: white;}
.music-file-link-black a:hover { color: black; text-decoration: none;  background: white;}
.music-file-link-black a:active { color: black;  text-decoration: none; background: white;}

.item-card-footer{
  margin-top: -4px;
  border-bottom-right-radius: .4vw;
  border-bottom-left-radius: .4vw;
  padding-left: 1vw;
  padding: 1vw;
  background-color: #232323;
  color: white;
}

@media screen and (min-width: 741px) {
  div.item-card-item-name {
    font-size: 1.2vw;
  }
  .body-right{
    width: 100%;

  }
}


@media screen and (max-width: 740px) {
  .item-card{
    width: 98vw;
    margin-right:2vw;
    height: 60vh;
  }
  div.item-card-item-name {
    font-size: 4vh;
  }
  .body-right{
    padding: 0;
    width: 100vw;
    min-height: 100vh;
  }
  .item-image-wrapper{
    height: 50vh;
    overflow-y: auto;
  }
}
